/* eslint-disable no-restricted-imports */

import { makeStyles, TextField, Toolbar, Typography } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import OrderList from "../modules/OrderHistory/OrderList"
import ItemList from "../modules/OrderHistory/ItemList"
import {
  addToBudgetBalance,
  getItemHistory,
  getOrderHistory,
  getUserActivity,
  getUserTransfer,
  getUsersBudgets,
  getWalletTransactions
} from "../modules/OrderHistory/_axios/orderHistoryCrud.js"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import { useParams, withRouter } from "react-router"
import moment from "moment"
import { Tab, Tabs } from "react-bootstrap"
import { getUserByID } from "../modules/Users/_axios/userCrud"
import SetDateRangeDialog from "../modules/Customers/Locations/Meals/SetDateRangeDialog"
import { formatDate, formatDateForApi } from "../modules/Common/momentFunctions"
import DishLoader from "../modules/Common/DishLoader"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import UserActivityList from "../modules/OrderHistory/UserActivityList"
import BudgetDialog from "../modules/OrderHistory/BudgetDialog"
import UserBudgetsList from "../modules/OrderHistory/UserBudget/UserBudgetsList"
import UserTransfersList from "../modules/OrderHistory/UserTransfer/UserTransfersList"
import TransactionsList from "../modules/OrderHistory/TransactionsList"

const useStyles = makeStyles(theme => ({
  tabHeader: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    backgroundColor: "#fff"
  },
  textField: {
    margin: theme.spacing(1),
    width: 150
  },
  toolbar: {
    padding: "0 2.25rem"
  },
  spacer: {
    flex: "1 1 100%"
  },
  wrapper: {
    position: "relative",
    width: "100%",
    minHeight: 400
  }
}))

function OrderHistoryPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  let { id: currentUserID } = useParams()

  const [activeTab, setActiveTab] = useState("activities")

  const [ordersData, setOrdersData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [itemsData, setItemsData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [userActivityData, setUserActivityData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [usersBudgetsData, setUsersBudgetsData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [usersTransfersData, setUsersTransfersData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [walletTransactionsData, setWalletTransactionsData] = useState({
    data: [],
    isLoading: true,
    notRequested: true
  })
  const [budgetDialogData, setBudgetDialogData] = useState({
    budgetID: null,
    balance: 0
  })
  const [transferDialogData, setTransferDialogData] = useState({
    transferID: null,
    balance: 0
  })
  const [currentUsername, setCurrentUsername] = useState("")

  // DateRange related functionality
  //------------------------------------------------------
  const [currentDateRange, setCurrentDateRange] = useState({
    from: null,
    to: null
  })
  const [dateRangeDialogState, setDateRangeDialogState] = useState({
    show: false
  })

  function handleOpenSetDateRangeDialog() {
    setDateRangeDialogState({
      show: true,
      from: currentDateRange.from,
      to: currentDateRange.to
    })
  }

  function handleSetDateRange(from, to) {
    setCurrentDateRange({ from, to })
    setDateRangeDialogState({ show: false })
  }
  //------------------------------------------------------

  function fetchUser(cancelToken) {
    getUserByID(currentUserID, cancelToken.token)
      .then(({ data }) => {
        setCurrentUsername(data.FirstName + " " + data.LastName)
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_USER_BY_ID"
          })
        )
      )
  }

  function fetchItemHistory(from, to, cancelToken) {
    setItemsData({ ...ordersData, isLoading: true })
    getItemHistory(currentUserID, from, to, cancelToken.token)
      .then(({ data }) => {
        setItemsData({
          data: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_ITEM_HISTORY"
          })
        )
      )
  }

  function fetchOrderHistory(from, to, cancelToken) {
    setOrdersData({ ...ordersData, isLoading: true })
    getOrderHistory(currentUserID, from, to, cancelToken.token)
      .then(({ data }) => {
        setOrdersData({
          data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => {
        setOrdersData({
          data: [],
          isLoading: false,
          notRequested: false
        })
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_ORDER_HISTORY"
          })
        )
      })
  }

  function fetchUserTransfer(from, to, cancelToken) {
    setUsersTransfersData({ ...usersTransfersData, isLoading: true })
    getUserTransfer(currentUserID, from, to, cancelToken.token)
        .then(({ data }) => {
          setUsersTransfersData({
            data: data,
            isLoading: false,
            notRequested: false
          })
        })
        .catch(error => {
          setUsersTransfersData({
            data: [],
            isLoading: false,
            notRequested: false
          })
          handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_GET_USER_TRANSFER"
              })
          )
        })
  }

  function fetchUserActivity(from, to, cancelToken) {
    setUserActivityData({ ...userActivityData, isLoading: true })
    getUserActivity(currentUserID, from, to, cancelToken.token)
      .then(({ data }) => {
        setUserActivityData({
          data: data.items,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => {
        setUserActivityData({
          data: [],
          isLoading: false,
          notRequested: false
        })
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_USER_ACTIVITY"
          })
        )
      })
  }

  function fetchUserBudgets(cancelToken) {
    setUsersBudgetsData({ ...usersBudgetsData, isLoading: true })
    getUsersBudgets(currentUserID, cancelToken.token)
      .then(({ data }) => {
        setUsersBudgetsData({
          data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => {
        setUsersBudgetsData({
          data: [],
          isLoading: false,
          notRequested: false
        })
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_USER_BUDGETS"
          })
        )
      })
  }

  function fetchWalletTransactions(cancelToken) {
    setWalletTransactionsData({ ...walletTransactionsData, isLoading: true })
    getWalletTransactions(currentUserID, cancelToken.token)
      .then(({ data }) => {
        setWalletTransactionsData({
          data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => {
        setWalletTransactionsData({
          data: [],
          isLoading: false,
          notRequested: false
        })
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_TRANSACTION_DATA_FOR_WALLET"
          })
        )
      })
  }

  useEffect(() => {
    if (currentDateRange.from && currentDateRange.to) {
      const params = {
        fromDate: formatDateForApi(currentDateRange.from),
        toDate: formatDateForApi(currentDateRange.to)
      }

      props.history.push({
        pathname: `/user/${currentUserID}/order-history`,
        search: "?" + new URLSearchParams(params).toString()
      })

      const cancelToken = axios.CancelToken.source()
      fetchOrderHistory(params.fromDate, params.toDate, cancelToken)
      fetchItemHistory(params.fromDate, params.toDate, cancelToken)
      fetchUserActivity(params.fromDate, params.toDate, cancelToken)
      fetchUserTransfer(params.fromDate, params.toDate, cancelToken)
      return () => cancelToken.cancel()
    }
  }, [currentDateRange])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    const from = urlParams.get("fromDate")
      ? new Date(urlParams.get("fromDate"))
      : new Date(
          moment()
            .startOf("month")
        )
    const to = urlParams.get("toDate")
      ? new Date(urlParams.get("toDate"))
      : new Date(
          moment()
            .endOf("month")
        )
    const tab = urlParams.get("tab") || "activities";

    setCurrentDateRange({ from, to })
    setActiveTab(tab);

    const cancelToken = axios.CancelToken.source()
    fetchUser(cancelToken)
    fetchUserBudgets(cancelToken)
    fetchWalletTransactions(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  function updateBalance(amount) {
    const usersBudgets = [...usersBudgetsData.data]
    const changedBudget = usersBudgets.find(item => item.budgetID === budgetDialogData.budgetID)
    changedBudget.balance += amount
    setUsersBudgetsData({...usersBudgetsData, data: usersBudgets})
  }

  return (
    <>
      <SetDateRangeDialog
        show={dateRangeDialogState.show}
        saveRange={(from, to) => handleSetDateRange(from, to)}
        closeDialog={() => setDateRangeDialogState({ show: false })}
        maxDate={dateRangeDialogState.maxDate}
        minDate={dateRangeDialogState.minDate}
        maxRange={35}
        initialStartDate={dateRangeDialogState.from}
        initialEndDate={dateRangeDialogState.to}
      />
      <BudgetDialog
        show={!!budgetDialogData.budgetID}
        currentBalance={budgetDialogData.balance}
        closeDialog={() => setBudgetDialogData({ budgetID: null })}
        submitData={(amount, comments) => addToBudgetBalance(budgetDialogData.budgetID, amount, comments)}
        updateBalance={updateBalance}
      />
      {currentUsername && (
        <Typography variant="h3" style={{ textAlign: "right" }}>
          <FormattedMessage id="TITLE" /> {currentUsername}
        </Typography>
      )}
      <div className="row h-100 mt-4">
        <div className="col-md-12 p-0">
          <div className="card card-custom position-absolute h-100 w-100">
            {!["budgets", "wallet"].includes(activeTab) &&
              <Toolbar className={classes.toolbar}>
                <div className="d-flex flex-row justify-content-center align-items-baseline">
                  <TextField
                    className={classes.textField}
                    inputProps={{
                      readOnly: true
                    }}
                    margin="normal"
                    label={intl.formatMessage({
                      id: "DATE_FIELD.FROM"
                    })}
                    fullWidth
                    value={
                      currentDateRange.from
                        ? formatDate(currentDateRange.from)
                        : ""
                    }
                    onClick={handleOpenSetDateRangeDialog}
                  />
                  <div className={classes.divider}>-</div>
                  <TextField
                    className={classes.textField}
                    inputProps={{
                      readOnly: true
                    }}
                    margin="normal"
                    label={intl.formatMessage({
                      id: "DATE_FIELD.TO"
                    })}
                    fullWidth
                    value={
                      currentDateRange.to ? formatDate(currentDateRange.to) : ""
                    }
                    onClick={handleOpenSetDateRangeDialog}
                  />
                </div>
                <div className={classes.spacer} />
              </Toolbar>
            }
            <div className="card-body pt-0 overflow-auto">
              <Tabs
                activeKey={activeTab}
                onSelect={value => setActiveTab(value)}
                className={classes.tabHeader}
                unmountOnExit
              >
                <Tab
                  eventKey="activities"
                  title={<FormattedMessage id="TABS.ACTIVITIES" />}
                >
                  <div className={classes.wrapper}>
                    {userActivityData.isLoading ? (
                      <DishLoader centered />
                    ) : userActivityData.data.length !== 0 ? (
                      <UserActivityList userActivityData={userActivityData.data} />
                    ) : userActivityData.notRequested ? null : (
                      <Typography variant="h4" className={classes.heading}>
                        <FormattedMessage id="COMMON.NO_RESULTS" />
                      </Typography>
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey="orders"
                  title={<FormattedMessage id="TABS.ORDERS" />}
                >
                  <div className={classes.wrapper}>
                    {ordersData.isLoading ? (
                      <DishLoader centered />
                    ) : ordersData.data.length !== 0 ? (
                      <OrderList ordersData={ordersData} />
                    ) : ordersData.notRequested ? null : (
                      <Typography variant="h4" className={classes.heading}>
                        <FormattedMessage id="COMMON.NO_RESULTS" />
                      </Typography>
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey="items"
                  title={<FormattedMessage id="TABS.ITEMS" />}
                >
                  <div className={classes.wrapper}>
                    {itemsData.isLoading ? (
                      <DishLoader centered />
                    ) : itemsData.data.length !== 0 ? (
                        <>
                          <ItemList itemsData={itemsData} />
                        </>
                    ) : itemsData.notRequested ? null : (
                      <Typography variant="h4" className={classes.heading}>
                        <FormattedMessage id="COMMON.NO_RESULTS" />
                      </Typography>
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey="budgets"
                  title={<FormattedMessage id="TABS.BUDGETS" />}
                >
                  <div className={classes.wrapper}>
                    {usersBudgetsData.isLoading ? (
                      <DishLoader centered />
                    ) : usersBudgetsData.data.length !== 0 ? (
                      <UserBudgetsList itemsData={usersBudgetsData} openBudgetDialog={(budgetID, balance) => setBudgetDialogData({ budgetID, balance })} />
                    ) : usersBudgetsData.notRequested ? null : (
                      <Typography variant="h4" className={classes.heading}>
                        <FormattedMessage id="COMMON.NO_RESULTS" />
                      </Typography>
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey="wallet"
                  title={<FormattedMessage id="TABS.WALLET" />}
                >
                  <div className={classes.wrapper}>
                    {walletTransactionsData.isLoading ? (
                      <DishLoader centered />
                    ) : walletTransactionsData.data.length !== 0 ? (
                      <TransactionsList itemsData={walletTransactionsData} type={'wallet'}/>
                    ) : walletTransactionsData.notRequested ? null : (
                      <Typography variant="h4" className={classes.heading}>
                        <FormattedMessage id="COMMON.NO_RESULTS" />
                      </Typography>
                    )}
                  </div>
                </Tab>
                <Tab
                    eventKey="bank_transfer"
                    title={<FormattedMessage id="TABS.TRANSFER" />}
                >
                  <div className={classes.wrapper}>
                    {usersTransfersData.isLoading ? (
                        <DishLoader centered />
                    ) : usersTransfersData.data.length !== 0 ? (
                        <UserTransfersList itemsData={usersTransfersData} openBudgetDialog={(transferID, balance) => setTransferDialogData({ transferID, balance })} />
                    ) : usersTransfersData.notRequested ? null : (
                        <Typography variant="h4" className={classes.heading}>
                          <FormattedMessage id="COMMON.NO_RESULTS" />
                        </Typography>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(
  withRouter(connect(null, snackbarRedux.actions)(OrderHistoryPage))
)
