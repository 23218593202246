import React, {useEffect, useState} from "react"
import {dayCollection} from "../../Common/dayCollection"
import {
    makeStyles,
    FormControlLabel,
    Checkbox,
    TextField,
    TableRow,
    TableCell,
    FormControl,
    Select,
    FilledInput,
    MenuItem,
} from "@material-ui/core"
import {toAbsoluteUrl} from "../../../../_metronic/_helpers"
import {formatDate} from "../../Common/momentFunctions"
import {FormattedMessage, injectIntl} from "react-intl"
import SetDateRangeDialog from "./SetDateRangeDialog"

const useStyles = makeStyles(theme => ({
    textField: {
        margin: 0, minWidth: 110
    }, optionGroupField: {
        margin: 0, minWidth: 90
    }, priorityField: {
        margin: 0, width: 80
    }, input: {
        padding: theme.spacing(1)
    }, divider: {
        margin: theme.spacing(0, 1)
    }, iconDisplay: {
        maxHeight: 100, maxWidth: 174, width: "100%", minWidth: '100px'
    }, collapsibleRow: {
        "& > *": {
            borderBottom: "unset"
        }
    }
}))

function MealItem({
                      row, onSelect, selected, onItemFieldChange, intl
                  }) {
    const classes = useStyles()
    const [record, setRecord] = useState(undefined);
    const [internalRecord, setInternalRecord] = useState(undefined);

    useEffect(() => {
        setRecord({
            ...row,
            //Options: row.Options && row.Options.length > 0 ?
            //    [...row.Options] :
            //    [
            //        ...row.Item.OptionGroups.map(optionGroup => ({
            //            OptionGroupId: optionGroup.id,
            //            Label: optionGroup.Lable,
            //            Type: optionGroup.isFree ? 1 : 2,
            //            Order: optionGroup.Order || 0,
            //            MealItemId: row.id
            //        }))
            //    ],
            Options: row.Options && row.Options.length > 0
                ? [...row.Options]
                : row.IsCombo
                    ? []
                    : [
                        ...row.Item.OptionGroups.map(optionGroup => ({
                            OptionGroupId: optionGroup.id,
                            Label: optionGroup.Lable,
                            Type: optionGroup.isFree ? 1 : 2,
                            Order: optionGroup.Order || 0,
                            MealItemId: row.id
                        }))
                    ]

        });
        setInternalRecord({
            ...row,
            //Options: row.Options && row.Options.length > 0 ?
            //    [...row.Options] :
            //    [
            //        ...row.Item.OptionGroups.map(optionGroup => ({
            //            OptionGroupId: optionGroup.id,
            //            Label: optionGroup.Lable,
            //            Type: optionGroup.isFree ? 1 : 2,
            //            Order: optionGroup.Order || 0,
            //            MealItemId: row.id
            //        }))
            //    ]
            Options: row.Options && row.Options.length > 0
                ? [...row.Options]
                : row.IsCombo
                    ? []
                    : [
                        ...row.Item.OptionGroups.map(optionGroup => ({
                            OptionGroupId: optionGroup.id,
                            Label: optionGroup.Lable,
                            Type: optionGroup.isFree ? 1 : 2,
                            Order: optionGroup.Order || 0,
                            MealItemId: row.id
                        }))
                    ]
        });
    }, [])

    const [dateRangeDialogState, setDateRangeDialogState] = useState({
        show: false
    })

    useEffect(() => {
        setRecord({
            ...row,
            //Options: row.Options && row.Options.length > 0 ?
            //    [...row.Options] :
            //    [
            //        ...row.Item.OptionGroups.map(optionGroup => ({
            //            OptionGroupId: optionGroup.id,
            //            Label: optionGroup.Lable,
            //            Type: optionGroup.isFree ? 1 : 2,
            //            Order: optionGroup.Order || 0,
            //            MealItemId: row.id
            //        }))
            //    ]
            Options: row.Options && row.Options.length > 0
                ? [...row.Options]
                : row.IsCombo
                    ? []
                    : [
                        ...row.Item.OptionGroups.map(optionGroup => ({
                            OptionGroupId: optionGroup.id,
                            Label: optionGroup.Lable,
                            Type: optionGroup.isFree ? 1 : 2,
                            Order: optionGroup.Order || 0,
                            MealItemId: row.id
                        }))
                    ]
        });
    }, [row]);

    function onItemFieldChangeInternal(value, field) {
        const data = {...record};
        data[field] = value
        setRecord(data);
    }

    function handleChangeOptionGroupType(event, label) {
        const value = event.target.value;
        const changedOptionGroups = record.Options.map(optionGroup =>
            optionGroup.Label === label
                ? { ...optionGroup, Type: value }
                : optionGroup
        );
        const updatedRecord = {
            ...record,
            Options: changedOptionGroups,
        };
        setRecord(updatedRecord);
    }

    if (!record) {
        return <></>;
    }

    return (<React.Fragment>
        <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={record.IsCombo ? record.Combo.Id : record.Item.ItemID}
            className={classes.collapsibleRow}
        >
            <TableCell>
                <Checkbox
                    checked={selected.indexOf(record.IsCombo ? record.Combo.Id : record.Item.ItemID) !== -1}
                    style={{padding: 12}}
                    onChange={() => {
                        onSelect({
                            itemId: record.IsCombo ? record.Combo.Id : record.Item.ItemID, id: record.id
                        })
                    }}
                    color="secondary"
                />
            </TableCell>
            <TableCell>
                <img
                    src={record.IsCombo ? record.Combo.ImageUrl : record.Item.Image && record.Item.Image.length !== 0 ? record.Item.Image[0].URL : ""}
                    onError={e => {
                        e.target.onerror = null
                        e.target.src = toAbsoluteUrl(`/media/svg/image_filler_icon.svg`)
                    }}
                    alt="Item"
                    className={classes.iconDisplay}
                />
            </TableCell>
            <TableCell align="left">{record.IsCombo ? record.Combo.Name : record.Item.Name}</TableCell>
            <TableCell align="left">{record.IsCombo ? "" : record.Item.SupplierName}</TableCell>
            <TableCell align="left">
                <TextField
                    className={classes.priorityField}
                    margin="normal"
                    variant="filled"
                    type="number"
                    hiddenLabel
                    inputProps={{
                        min: 0, className: classes.input
                    }}
                    fullWidth
                    value={record.Priority}
                    onBlur={() => onItemFieldChange(record, internalRecord)}
                    onChange={event => onItemFieldChangeInternal(+event.target.value, "Priority", record.IsCombo ? record.Combo.Id : record.Item.ItemID)}
                />
            </TableCell>
            <TableCell>
                {record.Options.map(optionGroup => (<div key={optionGroup.Label}>
                    <div className=" d-flex justify-content-end align-items-center pr-0 text-right">
                        {optionGroup.Label}
                    </div>
                    <div className=" pl-3 pt-3 pb-3">
                        <FormControl
                            variant="filled"
                            className={classes.optionGroupField}
                            fullWidth
                        >
                            <Select
                                value={optionGroup.Type}
                                onBlur={() => onItemFieldChange(record, internalRecord)}
                                onChange={event => handleChangeOptionGroupType(event, optionGroup.Label)}
                                input={<FilledInput
                                    name="optionGroupStatus"
                                    inputProps={{
                                        className: classes.input
                                    }}
                                />}
                            >
                                <MenuItem value={0}>
                                    <FormattedMessage id="MEAL_ITEMS.OPTION_GROUP.TYPE.HIDDEN"/>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <FormattedMessage id="MEAL_ITEMS.OPTION_GROUP.TYPE.FREE"/>
                                </MenuItem>
                                <MenuItem value={2}>
                                    <FormattedMessage id="MEAL_ITEMS.OPTION_GROUP.TYPE.PAID"/>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>))}
            </TableCell>
            <TableCell>
                {record.IsCombo
                    ? (<div></div>)
                    : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                        {record.Item.Price}
                        </div>)}
            </TableCell>
            <TableCell align="left" width={180}>
                <TextField
                    className={classes.priorityField}
                    margin="normal"
                    variant="filled"
                    type="number"
                    inputProps={{
                        min: 0, className: classes.input
                    }}
                    hiddenLabel
                    fullWidth
                    value={record.ClientPrice}
                    onBlur={() => onItemFieldChange(record, internalRecord)}
                    onChange={event => onItemFieldChangeInternal(+event.target.value, "ClientPrice", record.IsCombo ? record.Combo.Id : record.Item.ItemID)}
                />
            </TableCell>
            <TableCell align="left" width={180}>
                <TextField
                    className={classes.priorityField}
                    margin="normal"
                    variant="filled"
                    type="number"
                    hiddenLabel
                    inputProps={{
                        min: 0, className: classes.input
                    }}
                    fullWidth
                    value={record.CustomerPrice}
                    onBlur={() => onItemFieldChange(record, internalRecord)}
                    onChange={event => onItemFieldChangeInternal(+event.target.value, "CustomerPrice", record.IsCombo ? record.Combo.Id : record.Item.ItemID)}
                />
            </TableCell>
            <TableCell align="left">
                {dayCollection.map(day => (<FormControlLabel
                    key={day.value}
                    style={{margin: 0}}
                    control={<Checkbox
                        checked={record.Days.includes(day.value)}
                        disabled={record.IsCombo ? false : record.Item.AvilabiltyDays.length > 0 ? !record.Item.AvilabiltyDays.includes(day.value) : false}
                        style={{padding: 0}}
                        onBlur={() => onItemFieldChange(record, internalRecord)}
                        onChange={(event) => {
                            const newDays = [...record.Days];
                            const id = day.value;
                            const index = newDays.indexOf(id);
                            if (index === -1) {
                                newDays.push(id);
                            } else {
                                newDays.splice(index, 1);
                            }
                            setRecord(
                                {
                                    ...record,
                                    Days: newDays,
                                }
                            )
                        }}
                        color="secondary"
                    />}
                    label={day.label}
                    labelPlacement="end"
                />))}
            </TableCell>
            <TableCell>
            {record.IsCombo
                ? (<div></div>)
                : (
                    <div className="">
                    <FormattedMessage id="MEAL_ITEM.LABELS.DATE_AVAILABILITY" />
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <TextField
                            placeholder={intl.formatMessage({
                                id: "DATE_FIELD.FROM"
                            })}
                            className={classes.textField}
                            inputProps={{
                                className: classes.input, readOnly: true
                            }}
                            margin="normal"
                            variant="filled"
                            fullWidth
                            value={record.From ? formatDate(record.From) : ""}
                            onClick={() => {
                                setDateRangeDialogState({
                                    show: true,
                                    mealItemID: record.Item.ItemID,
                                    maxDate: record.Item.AvilableTo ? new Date(`${record.Item.AvilableTo}.000Z`) : null,
                                    minDate: record.Item.AvilableFrom ? new Date(`${record.Item.AvilableFrom}.000Z`) : null,
                                    from: record.From && record.From !== '0001-01-01T00:00:00' ? new Date(record.From) : new Date(),
                                    to: record.To && record.To !== '0001-01-01T00:00:00' ? new Date(record.To) : new Date()
                                })
                            }}
                        />
                        <div className={classes.divider}>-</div>
                        <TextField
                            placeholder={intl.formatMessage({
                                id: "DATE_FIELD.TO"
                            })}
                            className={classes.textField}
                            inputProps={{
                                className: classes.input, readOnly: true
                            }}
                            margin="normal"
                            variant="filled"
                            fullWidth
                            value={record.To ? formatDate(record.To) : ""}
                            onClick={() => setDateRangeDialogState({
                                show: true,
                                mealItemID: record.Item.ItemID,
                                maxDate: record.Item.AvilableTo ? new Date(`${record.Item.AvilableTo}.000Z`) : null,
                                minDate: record.Item.AvilableFrom ? new Date(`${record.Item.AvilableFrom}.000Z`) : null,
                                from: record.From,
                                to: record.To
                            })}
                        />
                    </div>
                </div>)}
            </TableCell>
        </TableRow>
        <SetDateRangeDialog
            show={dateRangeDialogState.show}
            saveRange={(from, to) => {
                const data = {
                    ...record,
                    From: from,
                    To: to
                };
                setRecord(data);
                setDateRangeDialogState({show: false})
                onItemFieldChange(data, internalRecord)
            }}
            closeDialog={() => setDateRangeDialogState({show: false})}
            maxDate={dateRangeDialogState.maxDate}
            minDate={dateRangeDialogState.minDate}
            initialStartDate={dateRangeDialogState.from}
            initialEndDate={dateRangeDialogState.to}
        />
    </React.Fragment>)
}

export default React.memo(injectIntl(MealItem))
