/* eslint-disable no-restricted-imports */

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import React, { useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { useStyles } from "../Common/_styles/filterPanelStyles"
import { formatDate } from "../Common/momentFunctions"
import SetDateRangeDialog from "../Customers/Locations/Meals/SetDateRangeDialog"

function FilterPanel({
  onSearchParamsSave,
  initialSearchParams,
  intl
}) {
  const classes = useStyles()

  const [searchParams, setSearchParams] = useState(initialSearchParams)
  const [dateRangeDialogState, setDateRangeDialogState] = useState({
    show: false
  })

  const handleSearchParamChange = name => data => {
    let value
    if (data.target) {
      value = data.target.value
    } else {
      value = data
    }
    setSearchParams(oldsearchParams => ({
      ...oldsearchParams,
      [name]: value
    }))
  }

  function handleSetDateRange(from, to) {
    handleSearchParamChange("from")(from)
    handleSearchParamChange("to")(to)
    setDateRangeDialogState({ show: false })
  }

  function handleOpenSetDateRangeDialog() {
    setDateRangeDialogState({
      show: true,
      from: searchParams.from,
      to: searchParams.to
    })
  }

  function handleSearchParamsSubmit(event) {
    event.preventDefault()
    onSearchParamsSave(searchParams)
  }

  return (
    <>
      <SetDateRangeDialog
        show={dateRangeDialogState.show}
        saveRange={(from, to) => handleSetDateRange(from, to)}
        closeDialog={() => setDateRangeDialogState({ show: false })}
        maxDate={dateRangeDialogState.maxDate}
        minDate={dateRangeDialogState.minDate}
        maxRange={35}
        initialStartDate={dateRangeDialogState.from}
        initialEndDate={dateRangeDialogState.to}
      />
      <form
        className={classes.root}
        onSubmit={handleSearchParamsSubmit}
        autoComplete="off"
      >
        <div className="d-flex flex-row justify-content-center align-items-baseline">
          <TextField
            className={classes.textField}
            inputProps={{
              readOnly: true
            }}
            margin="normal"
            label={intl.formatMessage({
              id: "DATE_FIELD.FROM"
            })}
            fullWidth
            value={searchParams.from ? formatDate(searchParams.from) : ""}
            onClick={handleOpenSetDateRangeDialog}
          />
          <div className={classes.divider}>-</div>
          <TextField
            className={classes.textField}
            inputProps={{
              readOnly: true
            }}
            margin="normal"
            label={intl.formatMessage({
              id: "DATE_FIELD.TO"
            })}
            fullWidth
            value={searchParams.to ? formatDate(searchParams.to) : ""}
            onClick={handleOpenSetDateRangeDialog}
          />
        </div>
        <Button
          id="search_params_submit"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          dir="ltr"
          disabled={
            !searchParams.from ||
            !searchParams.to
          }
          className={`font-weight-bold my-3 ml-10`}
        >
          <span>
            <FormattedMessage id="FILTER.SEARCH_BUTTON" /> <SearchIcon />
          </span>
        </Button>
      </form>
    </>
  )
}

export default injectIntl(FilterPanel)
